<!--
 * @Description: 用户头像相关操作
 * @Author: luocheng
 * @Date: 2022-03-28 15:39:15
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 11:54:37
-->
<template>
	<div class="user-actions">
		<!-- 自定义实现 -->
		<section
			class="info-desc-box"
			:class="[showActions ? activeClassName : '']"
			:style="infoDescBoxStyle"
			@click="onToggleShow"
		>
			<!-- <i class="iconfont iconyonghuzhanghu userinfo"></i> -->
			<section class="base-info" v-if="userInfo">
				<img
					v-if="userInfo.avatar"
					:src="userInfo.avatar || defaultAvatar"
					alt=""
					class="avatar"
					:style="avatarStyle"
				/>
				<div class="right-cont">
					<p class="user-name" :style="nameStyle">{{ userInfo.name || '' }}</p>
					<p class="user-phone-num" :style="nameStyle">{{ userInfo.phone || '' }}</p>
				</div>
				<i class="iconfont" :class="!showActions ? 'iconjijia_jiantouxiangxia' : 'iconjijia_jiantouxiangshang'"></i>
			</section>
		</section>
		<!-- 内容 -->
		<article class="action-menu" :style="{ height: showActions ? '171px' : '0px' }">
			<ul class="actions">
				<section class="action-section">
					<li class="action-item" @click="onClearCache">
						<i class="iconfont iconqingsaoqingchu"></i>
						<span>{{ $t('components.UserActions.590825-0') }}</span>
					</li>
					<!-- <li class="action-item download">
            <i class="iconfont iconshoucewendang"></i>
            <span>下载操作手册</span>
          </li> -->
				</section>
				<section class="action-section">
					<li class="action-item user-center" @click="onUserCenter">
						<i class="iconfont icona-usergerenzhongxin"></i>
						<span>{{ $t('components.UserActions.590825-1') }}</span>
					</li>
				</section>
				<section class="action-section">
					<li class="action-item" @click="onQuit">
						<i class="iconfont iconkaiguantuichu"></i>
						<span>{{ $t('components.UserActions.590825-2') }}</span>
					</li>
				</section>
			</ul>
		</article>
		<!-- mask -->
		<div class="actions-mask" v-show="showActions" @click.self="onToggleShow"></div>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'UserActions',
	components: {},
	props: {
		infoDescBoxStyle: {
			type: Object,
			default: () => {
				return {
					padding: '5px 16px'
				};
			}
		},
		avatarStyle: {
			type: Object,
			default: () => {}
		},
		nameStyle: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			showActions: false,
			loading: true,
			userInfo: null,
			defaultAvatar: require('@/assets/images/default-avatar.png')
		};
	},
	computed: {
		...mapState(['systemConfig']),
		// 选中样式
		activeClassName() {
			const { theme_type = 1 } = this.systemConfig || {};
			if (theme_type === 2) {
				return 'active-two';
			}
			return 'active-one';
		}
	},
	created() {
		this.userInfo = this.$GetUserInfo();
	},
	mounted() {
		eventBus.$on('updateUserInfo', () => {
			this.userInfo = this.$GetUserInfo();
		});
	},
	methods: {
		/**
		 * @desc: 切换显示
		 */
		onToggleShow() {
			this.showActions = !this.showActions;
		},
		/**
		 * @desc: 用户中心
		 */
		onUserCenter() {
			this.$router.push({
				name: 'PedestalUserCenter'
			});
			this.onToggleShow();
		},
		/**
		 * @desc: 清除缓存
		 */
		onClearCache() {
			const loading = this.$loading({
				text: '清除中...'
			});
			this.showActions = false;
			setTimeout(() => {
				loading.close();
				this.$message.success(this.$t('components.UserActions.590825-4'));
			}, 1500);
		},
		/**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			this.$router.replace({
				name: 'Login'
			});
			eventBus.$emit('updateRoute');
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setNavHistory', []);
			this.$store.commit('setTargetGlobalTab', '');
		}
	},
	beforeDestroy() {
		eventBus.$off('updateUserInfo');
	}
};
</script>

<style lang="less" scoped>
@height: 48px;
@partWidth: 280px;
.user-actions {
	height: 100%;
	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}
	.info-desc-box {
		cursor: pointer;
		display: flex;
		height: 40px;
		&.active-one {
			color: #fff;
			opacity: 1;
			background: rgba(255, 255, 255, 0.2);
		}
	}
	.action-menu {
		position: fixed;
		right: 5px;
		z-index: 2999;
		width: 240px;
		box-sizing: border-box;
		box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 8%);
		transition: all 0.2s;
		overflow: hidden;
		background: #fff;
		.actions {
			box-sizing: border-box;
			width: 100%;
			padding: 0 8px;
			.action-section {
				box-sizing: border-box;
				padding: 8px 0;
				border-bottom: 1px solid #e9ebf0;
				&:last-of-type {
					border-bottom: 0;
				}
				.action-item {
					display: flex;
					height: 40px;
					overflow: hidden;
					box-sizing: border-box;
					padding: 9px 10px;
					border-radius: 4px;
					cursor: pointer;
					&:hover {
						background: #e9ebf0;
					}
					.iconfont {
						font-size: 14px;
						color: #4d5358;
						line-height: 22px;
					}
					span {
						flex: 1;
						margin-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #4d5358;
						line-height: 22px;
					}
				}
			}
		}
	}
	.actions-mask {
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: transparent;
		top: 0;
		left: 0;
		z-index: 2998;
		cursor: pointer;
	}
	.base-info {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		.avatar {
			height: 32px;
			width: 32px;
			margin: 4px 8px;
			border-radius: 8px;
			object-fit: cover;
		}
		.default-avatar {
			font-size: 24px;
			line-height: 40px;
			margin-right: 10px;
		}
		.right-cont {
			height: 100%;
			.user-name {
				color: #181b22;
				font-family: 'PingFang SC';
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
				letter-spacing: 0.5px;
				height: 20px;
			}
			.user-phone-num {
				color: #707786;
				font-family: 'PingFang SC';
				font-size: 12px;
				line-height: 18px;
				line-height: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				letter-spacing: 0.4px;
			}
		}
		i {
			color: #22262d;
			font-size: 18px;
			line-height: 40px;
			margin-left: 12px;
		}
	}
}
</style>
